import React from "react";
import { useQuery } from "@tanstack/react-query";
import Loading from "../Loading/Loading.jsx";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./ProductsDetails.css";
import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";
import { useParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import parseNestedJson from "../../Core/parseNestedJson.js";
import { endpoints } from "../../Core/ApiUrls.js";





function ProductsDetails() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);
  
  const { ProductId } = useParams(); // Get ProductId from URL parameters

  const adjustedProductId = parseInt(ProductId, 10) + 1; // Add 1 to ProductId (convert to number to ensure correctness)
  
  const { data, isLoading, isSuccess, error } = useQuery({
    queryKey: ["details", adjustedProductId], // Use adjustedProductId in the query key
    queryFn: () => fetch(endpoints.productDetails(adjustedProductId)).then((res) => res.json()),
  });



  return (
    <>
    {isSuccess && <div  className="hugy-body" dir="ltr">
      
      <div
      className="border-container"
      data-aos="fade-left"
      data-aos-duration="1000"
    >
      <h1 data-aos="fade-up" data-aos-duration="2700" className="gg">
        {data[0].product_name}
      </h1>
<Swiper
  className="pd-swiper"
  effect={"coverflow"}
  grabCursor={true}
  centeredSlides={true}
  slidesPerView={"auto"}
  coverflowEffect={{
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: false,
  }}
  navigation={true}
  autoplay={{
    delay: 3000,
  }}
  rewind={true}
  spaceBetween={10}
  pagination={{
    clickable: true,
    type: "bullets",
  }}
  modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}

>
  {/* Loop through each product */}
  {data.map((item, index) => {
    const parsedImages = parseNestedJson(item.product_images).images; // Parse the images
    return parsedImages.slice(1).map((image, imgIndex) => ( // Skip the first image
      <SwiperSlide key={`${index}-${imgIndex}`} className="pd-swiper-slide">
        <img src={image} alt={`Slide ${imgIndex + 1}`} />
      </SwiperSlide>
    ));
  })}
</Swiper>
      <div className="background-hugy" dir="rtl">
      <section className="products-details-section">
        <p
          className="products-details-text-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {/*Product Description*/}
          وصف المنتج
        </p>
        <p
          className="products-details-text-info"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {data[0].product_description}
        </p>
        <p
          className="products-details-text-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {/*Product ingredients*/}
          مكونات المنتج
        </p>
        <p
          className="products-details-text-info"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {data[0].product_ingredient}
        </p>
        <p
          className="products-details-text-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {/*Weight*/}
          الوزن
        </p>
        <p
          className="products-details-text-info"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {data[0].product_weight}
        </p>
        <p
          className="products-details-text-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {/*Packing*/}
التعبئة
        </p>
        <p
          className="products-details-text-info"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {data[0].product_packaging}
        </p>
      </section>
      </div>
    </div>
 
    </div>
    }
    {isLoading && <Loading />}
   </>
  );
}

export default ProductsDetails;
