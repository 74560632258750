import React, { useEffect } from "react";
import "./HomePage.css";
import Main from "../../Components/Main/Main";
import Home from "../../Components/Home/Home";

function HomePage({ homeData, isSuccessHome, productsData, isSuccessProducts }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="background">
      <Home data={homeData} isSuccess={isSuccessHome} />
      <Main data={productsData} isSuccess={isSuccessProducts} />
    </div>
  );
}

export default HomePage;