import React, { useState } from "react";
import "./navbar.css";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsList } from "react-icons/bs";
import { Link } from "react-router-dom";

function Navbar() {
  const [active, setActive] = useState("navBar");

  const showNav = () => {
    setActive("navBar activeNavbar");
  };

  const removeNav = () => {
    setActive("navBar");
  };

  return (
    <section className="navBarSection" >
      <header className="header flex">
        <div className="logoDiv">
          <Link to="/" className="logo flex">
            <img
              className="navImage"
              src="\images\final.png"
              alt="Biscoza"
            ></img>
          </Link>
        </div>
        <div className={active}>
          <ul className="navLists flex">
          <li className="navItem">
              <Link to="/ContactUs" className="navLink" onClick={removeNav}>
                تواصل معنا
              </Link>
            </li>
           
            <li className="navItem">
              <Link to="/AboutUs" className="navLink" onClick={removeNav}>
                من نحن
              </Link>
            </li>
            <li className="navItem">
              <Link to="/" className="navLink" onClick={removeNav}>
                الرئيسية
              </Link>
            </li>
          </ul>
          <div onClick={removeNav} className="closeNavbar">
            <AiFillCloseCircle className="icon" />
          </div>
        </div>

        <div onClick={showNav} className="toggleNavbar">
          <BsList className="icon" />
        </div>
      </header>
    </section>
  );
}

export default Navbar;