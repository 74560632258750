import './Loading.css';

function Loading(){
    return(
        <div className="loading-body">
        <div className="image">
            <img src='/images/Loading2.svg'/>
        </div>
        </div>
    );
}

export default Loading;
