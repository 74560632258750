import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {QueryClient,QueryClientProvider} from "@tanstack/react-query";


const queryClient = new QueryClient({}); 

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    <BrowserRouter scrollRestoration="auto">
      <App />
    </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
)