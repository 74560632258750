import React from "react";
import { useQuery } from "@tanstack/react-query";
import Loading from "../Pages/Loading/Loading";
import Navbar from "./Navbar/navbar";
import Footer from "./Footer/Footer";
import { endpoints } from "../Core/ApiUrls.js";

function Layout({ children }) {
  // Fetch Home data
  const {
    data: homeData,
    isLoading: isLoadingHome,
    isSuccess: isSuccessHome,
    error: homeError,
  } = useQuery({
    queryKey: ["home"],
    queryFn: () => fetch(endpoints.home()).then((res) => res.json()),
  });

  // Fetch Products data
  const {
    data: productsData,
    isLoading: isLoadingProducts,
    isSuccess: isSuccessProducts,
    error: productsError,
  } = useQuery({
    queryKey: ["products"],
    queryFn: () => fetch(endpoints.allProducts()).then((res) => res.json()),
  });

  // Combined loading and error states
  const isLoading = isLoadingHome || isLoadingProducts;
  const error = homeError || productsError;

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      <Navbar />
      {/* Pass data and state as props to the children */}
      {React.cloneElement(children, {
        homeData,
        isSuccessHome,
        productsData,
        isSuccessProducts,
      })}
      <Footer />
    </div>
  );
}

export default Layout;

/*
import React from "react";
import Navbar from "./Navbar/navbar";
import Footer from "./Footer/Footer";
import Loading from "./Loading/Loading";
import "./Layout.css";

const Layout = ({ children, isLoading }) => {
  return (
    <div className="layout-container">
      {isLoading && (
        <div className="loading-overlay">
          <Loading />
        </div>
      )}
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
*/