import "./AboutUs.css";
import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

function AboutUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="about-us" data-aos="fade-up" data-aos-duration="1500" dir="rtl">
      <h1 data-aos="fade-up" data-aos-duration="1500">
        من نحن
      </h1>
      <hr class="solid" data-aos="fade-up" data-aos-duration="1500"></hr>

      <h2 data-aos="fade-up" data-aos-duration="1500">
        حكاية تأسيس بيسكوزا
      </h2>
      <p data-aos="fade-up" data-aos-duration="1500">
      شركة سورية متخصصة في صناعة البسكويت تأسست عام 2023 بشغف كبير لتقديم منتجات عالية الجودة تجمع بين المكونات المتميزة والأصناف اللذيذة لتوفر تجربة طعم استثنائية لكل من يتذوقها.      </p>
      <p data-aos="fade-up" data-aos-duration="1500">
      نحن في شركة بيسكوزا منذ أن بدأنا نحرص على استخدام أجود المكونات لإنتاج أصناف ذات جودة عالية من البسكويت المحشي والمغطس بالشوكولا والمارشميلو اللذيذ مع إدخال وصفاتنا الشهيّة والمتميزة لتضفي لحظات من السعادة على حياتكم اليومية.       </p>
      <p data-aos="fade-up" data-aos-duration="1500">
      التزامنا بالجودة والتميز والمعايير العالمية لتحقيق رضا زبائننا هو ما يدفعنا للحفاظ على أعلى المعايير في كل منتج نقدمه من حيث جودة المكونات، النظافة والتعقيم، المختبرات وخطوط الإنتاج مُدارة بالكامل من قبل أجهزة الكومبيوتر والتحكم الآلي دون تدخل العامل البشري خلال مراحل الإنتاج، حتى اختيار الأغلفة ومواد التغليف الراقية والتي تليق بمنتجات بيسكوزا لنضيف بصمة متميزة في عالم البسكويت والشوكولا.      </p>
      <p data-aos="fade-up" data-aos-duration="1500">
      في بيسكوزا نؤمن أن قطعة من البسكويت قد تكون سبباً لسعادة تشعر بها خلال يومك      </p>
      {/*
      <p data-aos="fade-up" data-aos-duration="1500">
        Whether you're savoring a moment alone or celebrating with loved ones, biscoza is here to make it special. Join us on this flavorful journey, and discover why biscoza is the name people trust for irresistible biscuits and chocolates.
      </p>
      <p data-aos="fade-up" data-aos-duration="1500">
        Because life is sweeter with biscoza.
      </p>
      <h2 data-aos="fade-up" data-aos-duration="1500">
        Meet Our Team
      </h2>
      <h3 data-aos="fade-up" data-aos-duration="1500">
        Emily Johnson - Founder & Chief Chocolatier
      </h3>
      <p data-aos="fade-up" data-aos-duration="1500">
        Emily’s passion for sweets led her to create unique flavor combinations
        that dance on your taste buds. With a background in culinary arts, she
        ensures every bite is a moment of bliss.
      </p>
      <h3 data-aos="fade-up" data-aos-duration="1500">
        Alex Rodriguez - Head Baker
      </h3>
      <p data-aos="fade-up" data-aos-duration="1500">
        Alex’s love for biscuits started at an early age. His dedication to
        perfection ensures that each biscuit is flaky, buttery, and
        irresistible.
      </p>
      <h2 data-aos="fade-up" data-aos-duration="1500">
        Our Evolution
      </h2>
      <p data-aos="fade-up" data-aos-duration="1500">
        From humble beginnings at local farmers’ markets to our bustling
        storefront, we’ve grown alongside our loyal customers. We’ve expanded
        our product line, introduced vegan options, and even won awards for our
        dark chocolate truffles. Join us on this sweet adventure, and let our
        treats brighten your day!
      </p>
      */}
    </section>
  );
}

export default AboutUs;
